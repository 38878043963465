<template>
  <div>
    <div class="detail">
      <div class="text" v-if="detail.taskName">
        <h3>
          <span v-if="detail.anonymousReview == 1">{{
            $t("Pub_Lab_AnonymousReview")
          }}</span
          >{{ detail.detailName }}
        </h3>
        <p>
          {{ $t("Review_times") }}：<span style="color: #377dff">{{
            detail.totals - detail.waits
          }}</span
          >/{{ detail.totals }}
        </p>
        <p>
          {{ $t("Pub_Lab_ExamSource") }}：
          {{ `【${getTaskType(detail.taskType)}】 ${detail.taskName}` }}
        </p>
        <p>
          {{ $t("Pub_Lab_ExamTime") }}： {{ dateFormat(detail.startTime) }} ~
          {{ dateFormat(detail.endTime) }}
        </p>
      </div>
      <a-button type="primary" class="back" @click="back">
        {{ $t("go_back") }}
        <!-- 返回 -->
      </a-button>
    </div>

    <div class="list">
      <h3>{{ $t("homework.StudentList") }}</h3>
      <div class="filter-wrap">
        <ListFilter
          only
          :name="$t('CM_Status')"
          :allObj="{
            name: $t('CM_All'),
            id: 0,
          }"
          :dataSource="statusData"
          :activeId="[queryParam.status]"
          @onChange="switchStatus"
        >
          <template v-slot:right>
            <div class="search">
              <a-input-search
                v-model:value="queryParam.keyword"
                :placeholder="$t('LB_QA_PleaseEnter') + $t('CM_LB_Name')"
                style="width: 220px"
                enter-button
                allow-clear
                @search="handleSearch(queryParam)"
              />
              <!-- 请输入需求征集名称 -->
            </div>
          </template>
        </ListFilter>
      </div>
      <div class="table-wrap">
        <a-table
          :columns="columns"
          :data-source="state.dataSource"
          :rowKey="(record, index) => index"
          :bordered="false"
          :pagination="pagination"
          :loading="state.loading"
          @change="handleTableChange"
        >
          <template #tooltip="{ text }">
            <a-tooltip>
              <template #title>{{ text }}</template>
              {{ text }}
            </a-tooltip>
          </template>
          <template #reviewTime="{ text }">
            {{ dateFormat(text) }}
          </template>
          <template #status="{ text }">
            <span style="color: #e8673e" v-if="text == 1">{{
              $t("Pub_WithoutApproval")
            }}</span>
            <span style="color: #44b44f" v-else>{{
              $t("LB_Teacher_HavaPerusal")
            }}</span>
          </template>
          <template #action="{ record }">
            <div class="action">
              <a-button
                type="link"
                :disabled="record.disabled"
                @click="review(record)"
                v-if="record.status == 1"
              >
                {{ $t("homework.approval") }}
              </a-button>
              <a-button type="link" @click="review(record)" v-else>
                {{ $t("cm_view") }}
              </a-button>
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, toRaw, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import { useFetchData } from "@/utils/useFetchData";
import { dateFormat } from "@/utils/tools";
import { getTaskType } from "@/utils/business";

import { getReviewMemberList, getReviewIsQuestions } from "@/api/exam";

import ListFilter from "@/components/new/filter/ListFilter.vue";
export default {
  components: {
    ListFilter,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const stateData = reactive({
      taskId: Number(route.query.taskId || 0),
      detailId: Number(route.query.detailId || 0),
      reexamId: Number(route.query.reexamId || 0),
      detail: {},
      statusData: [
        { id: 2, name: proxy.$t("LB_Teacher_HavaPerusal") }, // 已批阅
        { id: 1, name: proxy.$t("Pub_WithoutApproval") }, // 未批阅
      ],
      columns: [
        {
          title: proxy.$t("Pub_Lab_UserName"),
          // title: "姓名",
          dataIndex: "realName",
          width: 165,
          ellipsis: true,
          slots: { customRender: "tooltip" },
        },
        {
          title: proxy.$t("cm_account"),
          // title: "账号",
          dataIndex: "account",
          width: 165,
          ellipsis: true,
          slots: { customRender: "tooltip" },
        },
        {
          title: proxy.$t("cm_in_department"),
          // title: "所属部门",
          width: 165,
          dataIndex: "department",
          ellipsis: true,
          slots: { customRender: "tooltip" },
        },
        {
          title: proxy.$t("Pub_Lab_MarkingStatus"),
          // title: "批阅状态",
          dataIndex: "status",
          width: 165,
          slots: { customRender: "status" },
        },
        {
          title: proxy.$t("Pub_Lab_Review_time"),
          // title: "批阅时间",
          width: 165,
          dataIndex: "reviewTime",
          slots: { customRender: "reviewTime" },
        },
        {
          title: proxy.$t("cm_operate"),
          // title: "操作",
          dataIndex: "action",
          width: 80,
          slots: { customRender: "action" },
        },
      ],
    });

    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return getReviewMemberList(form).then((res) => {
        if (!stateData.detail.detailName) {
          stateData.detail = res.data.projectlist;
        }
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 10,
      tableSize: "middle", // 'default' | 'middle' | 'small'
      stripe: false,
    });

    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });

      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["10", "30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    let queryParam = reactive({
      taskId: stateData.taskId,
      detailId: stateData.detailId,
      reexamId: stateData.reexamId,
      keyword: "",
      status: store.getters.queryParams["mine/review/member"]?.status || 0,
    });

    const handleSearch = (searchModel) => {
      if (searchModel) {
        queryParam = searchModel;
      } else {
        queryParam.status = 0;
        queryParam.keyword = "";
      }
      setPageInfo({ current: 1, ...toRaw(queryParam) });
      reload();
    };

    const switchStatus = (arr) => {
      queryParam.status = arr[0];
      store.dispatch("setQueryParams", {
        mod: "mine/review/member",
        data: {
          status: arr[0],
        },
      });
      handleSearch(queryParam);
    };

    const back = () => {
      router.go(-1);
    };

    const review = async (item) => {
      if (item.status == 1) {
        item.disabled = true;
        const res = await getReviewIsQuestions({
          taskId: stateData.taskId,
          detailId: stateData.detailId,
          reexamId: stateData.reexamId,
          submitId: item.submitId,
        });
        setTimeout(() => {
          item.disabled = false;
        }, 500);
        if (res.ret != 0) return false;
      }
      router.push({
        path: "/exam/review/edit",
        query: {
          taskId: stateData.taskId,
          detailId: stateData.detailId,
          reexamId: stateData.reexamId,
          submitId: item.submitId,
          status: item.status == 1 ? 1 : 2,
        },
      });
    };

    return {
      dateFormat,
      getTaskType,
      ...toRefs(stateData),
      state,
      pagination,
      handleTableChange,
      queryParam,
      handleSearch,
      switchStatus,
      back,
      review,
    };
  },
};
</script>

<style lang="less" scoped>
.detail {
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  margin-bottom: 24px;
  .mixinFlex(space-between);
  .text {
    width: calc(100% - 100px);
    h3 {
      color: #333;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      margin-bottom: 12px;
      word-break: break-all;
      span {
        display: inline-block;
        background-color: #e8673e;
        color: #fff;
        font-size: 14px;
        font-weight: normal;
        padding: 0 6px;
        border-radius: 4px;
        margin-right: 4px;
        vertical-align: bottom;
      }
    }
    p {
      color: #333;
      font-size: 14px;
      line-height: 26px;
      margin: 4px 0 0;
    }
  }
}
.back {
  border-radius: 4px;
}
.list {
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  h3 {
    font-size: 20px;
    line-height: 26px;
    color: #333;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .filter-wrap {
    padding-bottom: 12px;
    margin-bottom: 16px;

    .filter {
      padding: 5px 0;
    }

    .search {
      padding: 5px 0;
      .mixinFlex(flex-end);

      ::v-deep(.ant-input-search) {
        padding-right: 1px;
        border-radius: 4px 0 0 4px;
      }

      ::v-deep(.ant-btn) {
        border-radius: 0 4px 4px 0;
      }
    }
  }
  .table-wrap {
    ::v-deep(.ant-table-pagination.ant-pagination) {
      float: none;
      text-align: center;
    }
    .action {
      ::v-deep(.ant-btn) {
        padding-right: 4px;
        padding-left: 0;
        margin-right: 10px;
        border: none !important;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
